import { InvalidNetworkError } from '@unifiprotocol/core-sdk'
import { DangerButton, PrimaryButton } from '@unifiprotocol/uikit'
import { useCallback, useState } from 'react'
import { ConnectionModal } from '../ConnectionModal'

export const ConnectWalletButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const onError = useCallback((error) => {
    setIsOpen(false)

    if (error instanceof InvalidNetworkError) {
      setError('Wrong network')
    } else {
      setError('Retry')
    }
  }, [])
  return (
    <div>
      {error && (
        <DangerButton variant="outline" onClick={() => setIsOpen(true)}>
          {error}
        </DangerButton>
      )}
      {!error && (
        <PrimaryButton variant="outline" onClick={() => setIsOpen(true)}>
          Connect
        </PrimaryButton>
      )}
      <ConnectionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConnect={() => setIsOpen(false)}
        onConnectionError={onError}
      />
    </div>
  )
}
