import { useCallback, useEffect } from 'react'

import { BaseEvent } from '../../EventBus/BaseEvent'
import { useUpdater } from '../../Hooks/useUpdater'
import { useAdapter } from '../../Hooks/useAdapter'
import Clock from '../../Utils/Clock'
import { useBalances } from '../../Hooks/useBalances'
import { AppEventBus } from '../../EventBus'
import { TransactionConfirmedEvent } from '../../Transactions/TransactionEvents'

export const BalancesUpdater = () => {
  const { adapter } = useAdapter()
  const { updateBalances } = useBalances()
  const { setUpdatingBalances } = useUpdater()

  const updateBalancesWrapper = useCallback(
    async (event?: BaseEvent<any>) => {
      setUpdatingBalances(true)
      await updateBalances()
      setUpdatingBalances(false)
    },
    [setUpdatingBalances, updateBalances]
  )

  useEffect(() => {
    if (!adapter.isConnected()) {
      return
    }
    updateBalancesWrapper()
  }, [adapter, updateBalancesWrapper])

  useEffect(() => {
    //AppEventBus.on(AddressChangedEvent, updateBalancesWrapper)

    return () => {
      //  AppEventBus.off(TokenListUpdatedEvent, updateBalancesWrapper)
    }
  }, [updateBalancesWrapper])

  useEffect(() => {
    Clock.on('SIXTY_SECONDS', updateBalancesWrapper)
    return () => {
      Clock.off('SIXTY_SECONDS', updateBalancesWrapper)
    }
  }, [updateBalancesWrapper])

  useEffect(() => {
    AppEventBus.on(TransactionConfirmedEvent, updateBalancesWrapper)
    return () => {
      AppEventBus.off(TransactionConfirmedEvent, updateBalancesWrapper)
    }
  }, [updateBalancesWrapper])

  return <></>
}
