import { Decimals } from '../Adapter/Contracts/ERC20/Decimals'
import { Symbol } from '../Adapter/Contracts/ERC20/Symbol'
import { Name } from '../Adapter/Contracts/ERC20/Name'

import { isEthAddress, isNativeAddress } from '../Utils/ETH'
import { Config } from '../Config'
import { cacheService } from './Cache'

import { WithAdapter } from './WithAdapter'
import { ContractUseCase, ExecutionResponse } from '@unifiprotocol/core-sdk'
import { Currency, BN, Opt } from '@unifiprotocol/utils'

// References:
// https://uniswap.org/docs/v2/SDK/fetcher/
class Fetcher extends WithAdapter {
  // todo optimize cache

  async fetchTokensData(tokenAddresses: string[]): Promise<Currency[]> {
    tokenAddresses.forEach((tokenAddress) => {
      if (!isNativeAddress(tokenAddress) && !isEthAddress(tokenAddress)) {
        throw Error(`Token address "${tokenAddress}" is not valid`)
      }
    })
    const tokenMap: Record<string, Currency> = tokenAddresses.reduce((map, tokenAddress) => {
      let token: Opt<Currency> = undefined

      if (isNativeAddress(tokenAddress)) {
        token = Config.nativeToken
      } else {
        token = cacheService.getToken(tokenAddress)
      }

      this.requireAdapter().initializeToken(tokenAddress)

      return {
        ...map,
        [tokenAddress]: token
      }
    }, {})
    const missingTokenAddresses: string[] = Object.keys(tokenMap).filter(
      (address) => !tokenMap[address]
    )
    if (missingTokenAddresses.length === 0) {
      return Object.values(tokenMap)
    }
    const useCases = missingTokenAddresses.reduce((list, tokenAddress) => {
      return [
        ...list,
        [new Symbol({ tokenAddress }), new Name({ tokenAddress }), new Decimals({ tokenAddress })]
      ]
    }, [] as ContractUseCase<any, any, any>[][])

    const results: string[][] = await this.requireMulticallAdapter()
      .executeGrouped(useCases)
      .then((groups) => groups.map((group: ExecutionResponse[]) => group.map((res) => res.value)))

    missingTokenAddresses.forEach((tokenAddress, i) => {
      const [symbol, name, decimals] = results[i]
      tokenMap[tokenAddress] = new Currency(tokenAddress, BN(decimals).toNumber(), symbol, name)
      cacheService.setToken(tokenMap[tokenAddress])
    })

    return Object.values(tokenMap)
  }

  async fetchTokenData(tokenAddress: string): Promise<Currency> {
    return this.fetchTokensData([tokenAddress]).then(([token]) => token)
  }
}

export default new Fetcher()
