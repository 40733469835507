import { atom, DefaultValue, selector } from 'recoil'
import { recoilPersist } from '../Utils/RecoilPersist'
import { Blockchains } from '@unifiprotocol/core-sdk'
import { Config } from '../Config'
import { Currency } from '@unifiprotocol/utils'
import { currencyFromJson } from '../Utils/Currency'

const { persistAtom } = recoilPersist<BalancesState>({
  parser: (state) => {
    if (!state) {
      return state
    }
    return Object.keys(state).reduce((t: BalancesState, key) => {
      const blockchain = key as Blockchains
      const st = state[blockchain]
      if (!st) return t
      const blockchainBalances = st.map(({ balance, currency }) => {
        return {
          balance,
          currency: currencyFromJson(currency)
        }
      })
      t[blockchain] = blockchainBalances
      return t
    }, {})
  }
})

export type TBlockchainBalance = { currency: Currency; balance: string }

export type BalancesState = {
  [K in Blockchains]?: TBlockchainBalance[]
}

const BalancesStore = atom<BalancesState>({
  key: 'BalancesStore',
  dangerouslyAllowMutability: true,
  default: {},
  effects_UNSTABLE: [persistAtom]
})

export const Balances = selector({
  key: 'BalancesSelector',
  dangerouslyAllowMutability: true,
  get: ({ get }) => {
    const state = get(BalancesStore)
    return state[Config.blockchain] || []
  },
  set: ({ set, get }, newValue: TBlockchainBalance[] | DefaultValue) => {
    const currentState = get(BalancesStore)
    set(
      BalancesStore,
      newValue instanceof DefaultValue
        ? newValue
        : { ...currentState, [Config.blockchain]: newValue }
    )
  }
})
