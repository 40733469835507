import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { Balances, TBlockchainBalance } from '../State/Balances'
import { useAdapter } from './useAdapter'

import { useCurrencies } from './useCurrencies'
import { Currency } from '@unifiprotocol/utils'
import { BalanceOf } from '../Adapter/Contracts/ERC20/BalanceOf'
import { Config } from '../Config'

export const useBalances = () => {
  const [balances, setBalances] = useRecoilState(Balances)
  const { adapter, multicallAdapter } = useAdapter()
  const { tokenList } = useCurrencies()

  const filterNativeToken = useCallback((token: Currency) => {
    return !token.equals(Config.nativeToken)
  }, [])

  const updateBalances = useCallback(async () => {
    console.debug('[Balances] updating for: ', adapter.getAddress())
    if (!adapter.isConnected()) {
      setBalances([])
      return
    }
    try {
      const fullTokenList = [...tokenList].filter(filterNativeToken)
      fullTokenList.forEach(({ address }) => {
        adapter.initializeToken(address)
      })
      const balances: TBlockchainBalance[] = fullTokenList.map((currency) => ({
        balance: '0',
        currency
      }))

      const balanceUseCases = balances.map(
        (balance) =>
          new BalanceOf({ tokenAddress: balance.currency.address, owner: adapter.getAddress() })
      )

      await multicallAdapter.execute(balanceUseCases).then((results: any[]) => {
        results.forEach((balanceRes: any, i: number) => {
          balances[i].balance = balanceRes.value
        })
      })

      await adapter.getBalance().then(({ balance }) => {
        balances.push({
          currency: Config.nativeToken,
          balance
        })
      })

      setBalances(balances)

      console.debug(
        '[Balances] updated:',
        balances
          .map((c) => `${c.currency.symbol}(${c.currency.toFactorized(c.balance, 2)})`)
          .join(', ')
      )
    } catch (error) {
      console.log('error fetching balances', error)
    }
  }, [adapter, setBalances, tokenList, filterNativeToken, multicallAdapter])

  const getBalanceByAddress = useCallback(
    (contractAddress: string, factorized: boolean = false) => {
      const refBalance = balances.find(
        (b) => b.currency.address.toLowerCase() === contractAddress.toLowerCase()
      )
      if (!refBalance) {
        return '0'
      }
      return factorized ? refBalance.currency.toFactorized(refBalance.balance) : refBalance.balance
    },
    [balances]
  )

  const getBalanceBySymbol = useCallback(
    (symbol: string, factorized: boolean = false) => {
      const refBalance = balances.find((b) => b.currency.symbol === symbol)
      if (!refBalance) {
        return '0'
      }
      return factorized ? refBalance.currency.toFactorized(refBalance.balance) : refBalance.balance
    },
    [balances]
  )

  const getBalanceByCurrency = useCallback(
    (currency: Currency, factorized: boolean = false) => {
      const refBalance = balances.find(
        (b) => b.currency.address.toLowerCase() === currency.address.toLowerCase()
      )
      if (!refBalance) {
        return '0'
      }
      return factorized ? refBalance.currency.toFactorized(refBalance.balance) : refBalance.balance
    },
    [balances]
  )

  return {
    updateBalances,
    getBalanceByAddress,
    getBalanceBySymbol,
    getBalanceByCurrency
  }
}
