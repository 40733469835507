export enum ERC20ContractMethod {
  Allowance = 'allowance',
  Approve = 'approve',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  BalanceOf = 'balanceOf',
  Name = 'name',
  Symbol = 'symbol',
  Decimals = 'decimals',
  GetVirtualPrice = 'getVirtualPrice',
  Burn = 'burn',
  TotalSupply = 'totalSupply',
  GetReserves = 'getReserves'
}
