import { useState } from 'react'
import { Network } from '../Entities/Network'
import { Config } from '../Config'
import { Blockchains } from '@unifiprotocol/core-sdk'

const hiddenNetworks: string[] = []

const networks = [
  // new Network(
  //   'Binance Smart Chain',
  //   Blockchains.Binance,
  //   'https://assets.unifiprotocol.com/BNB.png',
  //   'https://utrade.finance/?blockchain=binance',

  // ),
  new Network('Ethereum', Blockchains.Ethereum, 'https://assets.unifiprotocol.com/ETH.png'),
  new Network('Binance', Blockchains.Binance, 'https://assets.unifiprotocol.com/BNB.png'),
  new Network('Ropsten', Blockchains.EthereumRopsten, 'https://assets.unifiprotocol.com/ETH.png')
  // new Network(
  //   'Binance Smart Chain Testnet',
  //   Blockchains.BinanceTestnet,
  //   'https://assets.unifiprotocol.com/BNB.png',
  //   'https://utrade.finance/?blockchain=binance-testnet',
  //   ['Test', 'V2']
  // ),
]

export const useNetwork = () => {
  const [network, setNetwork] = useState(
    networks.find((network) => network.short.toUpperCase() === Config.blockchain.toUpperCase())!
  )

  return {
    networks: networks.filter((n) => !hiddenNetworks.includes(n.short)),
    network,
    setNetwork
  }
}
