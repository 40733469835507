import EventEmitter from 'eventemitter3'
import { BaseEvent } from './BaseEvent'

export const eventEmitterFactory = <Events extends symbol>() => {
  const emitter = new EventEmitter()
  type MyEventListner<T> = (event: T) => void

  return {
    on: <T extends BaseEvent<any>>(event: Events, fn: MyEventListner<T>) => emitter.on(event, fn),
    once: <T extends BaseEvent<any>>(event: Events, fn: MyEventListner<T>) =>
      emitter.once(event, fn),
    off: <T extends BaseEvent<any>>(event: Events, fn: MyEventListner<T>) => emitter.off(event, fn),
    emit: <T extends BaseEvent<any>>(event: T) => {
      const eventName = String(event.name).match(/Symbol\((.+)\)/)![1]
      console.debug(`[Emitted]: ${eventName}`)
      emitter.emit(event.name, event)
    }
  }
}
