import { UnifiThemeProvider, Themes } from '@unifiprotocol/uikit'
import { RecoilRoot } from 'recoil'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { Home } from './Views/Home'
import { BodyWrapper } from './Components/BodyWrapper'
import { Updater } from './Components/Updater'
import { ErrorBoundary } from './Components/ErrorBoundary'
import './App.css'
import { TokenLogoResolvers } from '@unifiprotocol/utils'
import { BlockchainConnector } from './Components/BlockchainConnector'
import { TransactionTracking } from './Transactions'
import { Config } from './Config'

function App() {
  return (
    <ErrorBoundary>
      <RecoilRoot>
        <Router>
          <UnifiThemeProvider
            theme={{ ...Themes.Dark }}
            options={{ tokenLogoResolver: TokenLogoResolvers[Config.blockchain] }}
          >
            <BlockchainConnector>
              <TransactionTracking />
              <Updater />
              <BodyWrapper>
                <Routes>
                  <Route path="/" element={<Home />} />
                </Routes>
              </BodyWrapper>
            </BlockchainConnector>
          </UnifiThemeProvider>
        </Router>
      </RecoilRoot>
    </ErrorBoundary>
  )
}

export default App
