import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { kfZoomIn } from '../../Styles/Keyframes'
import { useAdapter } from '../../Hooks/useAdapter'
import { OfflineConnectors } from '@unifiprotocol/core-sdk'

const defaultConnector = OfflineConnectors.Cloudflare

const Wrapper = styled.div`
  &.loaded {
    animation: ${kfZoomIn} 0.5s linear;
  }
`

export const BlockchainConnector: React.FC<{}> = ({ children }) => {
  const { connect, connectorName } = useAdapter()

  const [state, setLoading] = useState<'loading' | 'loaded'>('loading')

  useEffect(() => {
    // First ensure a connector
    connect(connectorName || defaultConnector)
      .then(() => {
        setLoading('loaded')
      })
      .catch((error: any) => {
        connect(defaultConnector).then(() => {
          setLoading('loaded')
        })
      })

    // do not want connectorName dep to avoid refreshing on connect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Wrapper className={state}>{state === 'loaded' && children}</Wrapper>
}
