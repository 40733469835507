import styled from 'styled-components'

export const SelectionList = styled.div``
export const ItemName = styled.span``

export const ItemTags = styled.div`
  margin-left: auto;
  font-size: 1.2rem;

  > span {
    &:not(:first-of-type) {
      margin-left: 0.5rem;
    }
  }
`

export const ItemLogo = styled.img`
  width: 1.8rem;
  margin-right: 0.5rem;
`

export const SelectionListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem 0.8rem;
  background: ${(p) => p.theme.grey};
  border-radius: ${(p) => p.theme.borderRadius};
  border: 2px solid transparent;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: 0.25s all;

  &.selected {
    cursor: default;
    color: ${(p) => p.theme.primaryColor};
    border-color: ${(p) => p.theme.primaryColor};
    background: ${(p) => p.theme.darkGrey};
  }

  &:hover {
    background: ${(p) => p.theme.darkGrey};
  }
`

export const SelectionModalWrapper = styled.div`
  width: 30rem;
  position: relative;
  @media (${(props) => props.theme.breakpoints.xs}) {
    min-width: 100% !important;
    max-width: 100% !important;
  }
`
export const SelectionOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
