import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { ERC20ContractMethod } from './ContractMethod'
import { BN, Currency, toHex } from '@unifiprotocol/utils'

export interface ApprovalContractParams {
  owner: string
  spender: string
  token: Currency
  approvalValue?: string
}

export class Approve extends ContractUseCase<ERC20ContractMethod, ApprovalContractParams> {
  constructor(params: ApprovalContractParams) {
    super(params.token.address, ERC20ContractMethod.Approve, params, true)
  }

  getArgs() {
    let approvalValue = BN(2).pow(256).minus(1).toFixed()
    if (this.params.approvalValue) {
      approvalValue = this.params.token.toPrecision(
        BN(this.params.approvalValue).decimalPlaces(0).toFixed()
      )
    }
    return [this.params.spender, toHex(approvalValue)]
  }
}
