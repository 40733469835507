import { TokenInputWithSelector } from '@unifiprotocol/uikit'
import { Currency, Opt } from '@unifiprotocol/utils'
import { useCallback, useMemo, useState } from 'react'
import { useMultiSend } from '../../Hooks/useMultiSend'
import styled from 'styled-components'
import { Config } from '../../Config'
import { useCurrencyWithBalance } from '../../Hooks/useCurrencyWithBalance'
import { useBalances } from '../../Hooks/useBalances'
import { useThrottle } from '../../Hooks/useThrottle'
import { AppEventBus } from '../../EventBus'
import { TokenSearchRequest } from '../../EventBus/Events'
import { usePrevious } from '../../Hooks/usePrevious'
import { filterTokens } from '../../Utils/Currency'
import { useUpdater } from '../../Hooks/useUpdater'

const TokenSelectorWrapper = styled.div``

export const TokenSelector = () => {
  const [search, setSearch] = useState<Opt<string>>()
  const prevSearch = usePrevious<Opt<string>>(search)
  const { updatingBalances } = useUpdater()

  const { totalAmount, setTransferType, token, setToken } = useMultiSend()
  const { tokenListWithBalances } = useCurrencyWithBalance()
  const { getBalanceByCurrency } = useBalances()

  const tokenList = useMemo(
    () => (search ? filterTokens(tokenListWithBalances, search) : tokenListWithBalances),
    [search, tokenListWithBalances]
  )

  const onTokenChange = useCallback(
    (token: Currency) => {
      setToken(token)
      setTransferType(token.equals(Config.nativeToken) ? 'native' : 'token')
    },
    [setToken, setTransferType]
  )

  const balance = useMemo(
    () => (token ? getBalanceByCurrency(token, true) : '0'),
    [getBalanceByCurrency, token]
  )

  useThrottle(
    (address: Opt<string>, prevAddress: Opt<string>) => {
      if (address && address !== prevAddress) {
        AppEventBus.emit(new TokenSearchRequest({ address }))
      }
    },
    500,
    [search, prevSearch]
  )

  return (
    <TokenSelectorWrapper>
      <TokenInputWithSelector
        balanceLoading={updatingBalances}
        tokenList={tokenList}
        balanceLabel="Balance"
        balance={balance}
        onAmountChange={() => {}}
        disableAmountChange={true}
        disableMaxAction={true}
        label="Total amount to be sent"
        token={token}
        amount={totalAmount}
        onSearch={setSearch}
        onTokenChange={onTokenChange}
      />
    </TokenSelectorWrapper>
  )
}
