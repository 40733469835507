import { Blockchains } from '@unifiprotocol/core-sdk'
import { chainOnStorageKey, getChainOnStorage, setChainOnStorage } from '../Utils/ChainStorage'
import { BinanceConfig } from './BinanceConfig'
import { EthereumConfig } from './EthereumConfig'
import { IConfig } from './IConfig'
import { RopstenConfig } from './RopstenConfig'

export const subdomainsBlockchainsMap: { [subdomain: string]: Blockchains } = {
  ropsten: Blockchains.EthereumRopsten,
  ethereum: Blockchains.Ethereum
}

const Configs: { [P in Blockchains]?: IConfig } = {
  [Blockchains.Ethereum]: EthereumConfig,
  [Blockchains.Binance]: BinanceConfig,
  [Blockchains.EthereumRopsten]: RopstenConfig
}

export const Config = (function () {
  const subdomain = window.location.hostname.split('.')[0]

  const chainOverrideSetter = new URL(window.location.href).searchParams
    .get('blockchain')
    ?.toLowerCase()
  if (chainOverrideSetter && subdomainsBlockchainsMap[chainOverrideSetter]) {
    setChainOnStorage(subdomainsBlockchainsMap[chainOverrideSetter])
  }
  const chainOverride = getChainOnStorage()
  if (chainOverride) {
    console.warn(
      `You are overriding domain with localStorage key: "${chainOnStorageKey}". Clear it or set with another value using ?blockchain=xxxx query param`
    )
  }

  const chain = chainOverride || subdomainsBlockchainsMap[subdomain]

  if (!Configs[chain]) {
    return Configs[Blockchains.Ethereum]
  }

  setChainOnStorage(chain)
  return Configs[chain]
})()!
