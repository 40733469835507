import { useAdapter } from '../../Hooks/useAdapter'
import { ConnectWalletButton } from './ConnectWalletButton'
import { WalletButton } from './WalletButton'

export const Wallet = () => {
  const { isConnected } = useAdapter()

  if (!isConnected) {
    return <ConnectWalletButton />
  }
  return <WalletButton />
}
