import { Currency } from '@unifiprotocol/utils'
import { Config } from '../../Config'
import { currencyFromJson } from '../../Utils/Currency'

import { PersistableCacheService } from './PersistableCacheService'

export enum CacheKey {
  Token = 'token-data'
}

class CacheService extends PersistableCacheService {
  setToken(token: Currency) {
    return this.set([CacheKey.Token, Config.blockchain, token.address], token, 30)
  }
  getToken(tokenAddress: string) {
    return this.get<Currency>([CacheKey.Token, Config.blockchain, tokenAddress])
  }
}

export const cacheService = new CacheService('multisender', { stdTTL: 60 }, [
  [CacheKey.Token, currencyFromJson]
])
