import { useMemo } from 'react'
import { useBalances } from './useBalances'
import { useCurrencies } from './useCurrencies'

export const useCurrencyWithBalance = () => {
  const { tokenList } = useCurrencies()
  const { getBalanceByCurrency } = useBalances()

  const tokenListWithBalances = useMemo(() => {
    return tokenList.map((currency) => ({
      currency,
      balance: getBalanceByCurrency(currency, true)
    }))
  }, [getBalanceByCurrency, tokenList])

  return { tokenListWithBalances }
}
