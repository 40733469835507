import { ERC20ABI, nonSuccessResponse, successResponse } from '@unifiprotocol/core-sdk'
import { BN, Currency } from '@unifiprotocol/utils'
import { ethers } from 'ethers'
import { useCallback } from 'react'
import { Allowance } from '../Adapter/Contracts/ERC20/Allowance'
import { Approve } from '../Adapter/Contracts/ERC20/Approve'
import { Config } from '../Config'
import { useTrackedTransactions } from '../Transactions/Hook'
import { TransactionType } from '../Transactions/Types'
import { useAdapter } from './useAdapter'

export const useContracts = () => {
  const { adapter, isConnected } = useAdapter()
  const { trackTransaction } = useTrackedTransactions()

  const approve = useCallback(
    async (token: Currency, spender = Config.multiSendAddress) => {
      if (!adapter || !isConnected) {
        throw new Error('Connect your wallet')
      }

      try {
        const approveUseCase = new Approve({
          token,
          owner: adapter!.getAddress(),
          spender
        })
        const contract = new ethers.Contract(
          token.address,
          ERC20ABI,
          adapter.getProvider().getSigner()
        )

        const res = await contract.approve(...approveUseCase.getArgs())

        if (res && res.hash) {
          trackTransaction({
            address: adapter!.getAddress(),
            params: { token: token.symbol },
            hash: res.hash,
            type: TransactionType.Approval
          })
        }
        return successResponse({
          hash: res.hash
        })
      } catch (error) {
        return nonSuccessResponse({
          err: error
        })
      }
      // return approveUseCase.execute(adapter).then((res) => {
      //   trackTransaction({
      //     address: adapter!.getAddress(),
      //     params: { token: token.symbol },
      //     hash: res.hash,
      //     type: TransactionType.Approval
      //   })
      //   return res
      // })
    },
    [adapter, isConnected, trackTransaction]
  )

  const getAllowance = useCallback(
    async (token: Currency, spender = Config.multiSendAddress): Promise<string> => {
      if (!adapter || !isConnected) {
        return '0'
      }

      const contract = new Allowance({
        tokenAddress: token.address,
        owner: adapter!.getAddress(),
        spender
      })
      await adapter.initializeToken(token.address)

      return contract
        .execute(adapter)
        .then((allowanceResponse) =>
          BN(allowanceResponse.value).isNaN()
            ? '0'
            : token.toFactorized(BN(allowanceResponse.value))
        )
    },
    [adapter, isConnected]
  )

  const hasAllowance = useCallback(
    async (token: Currency, spender, approvalValue: string = '0'): Promise<boolean> => {
      return getAllowance(token, spender).then(
        (allowance) =>
          !BN(allowance).isZero() && BN(allowance).isGreaterThanOrEqualTo(approvalValue)
      )
    },
    [getAllowance]
  )

  return {
    approve,
    hasAllowance
  }
}
