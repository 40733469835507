import { PrimaryButton } from '@unifiprotocol/uikit'
import { useState } from 'react'
import { useAdapter } from '../../Hooks/useAdapter'
import { shortAddress } from '../../Utils/Address'
import { ConnectedModal } from '../ConnectedModal'

export const WalletButton = () => {
  const { adapter } = useAdapter()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <PrimaryButton onClick={() => setIsOpen(true)} variant="outline">
        {shortAddress(adapter.getAddress())}
      </PrimaryButton>
      <ConnectedModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
}
