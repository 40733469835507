import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { ERC20ContractMethod } from './ContractMethod'

export interface BalanceOfParams {
  owner: string
  tokenAddress: string
}

export class BalanceOf extends ContractUseCase<ERC20ContractMethod, BalanceOfParams, string> {
  constructor(params: BalanceOfParams) {
    super(params.tokenAddress, ERC20ContractMethod.BalanceOf, params, false)
  }

  getArgs() {
    return [this.params.owner]
  }
}
