import { IAdapter, IMulticallAdapter } from '@unifiprotocol/core-sdk'
import { Opt } from '@unifiprotocol/utils'

export abstract class WithAdapter {
  protected adapter: Opt<IAdapter>
  protected multicallAdapter: Opt<IMulticallAdapter>
  setAdapter(adapter: IAdapter) {
    this.adapter = adapter
  }

  protected requireAdapter(): IAdapter {
    if (!this.adapter) {
      throw new Error('Cannot perform operation without adapter')
    }
    return this.adapter
  }

  setMulticallAdapter(multicallAdapter: IMulticallAdapter) {
    this.multicallAdapter = multicallAdapter
  }

  protected requireMulticallAdapter(): IMulticallAdapter {
    if (!this.multicallAdapter) {
      throw new Error('Cannot perform operation without adapter')
    }
    return this.multicallAdapter
  }
}
