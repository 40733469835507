import { DangerButton, PrimaryButton, SecondaryButton } from '@unifiprotocol/uikit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Config } from '../../Config'

import { useMultiSend } from '../../Hooks/useMultiSend'
import { ApproveButton, ApproveButtonProps } from '../ApproveButton'

const SendButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SendButton = () => {
  const { totalAmount, token, send, status, errors, reset } = useMultiSend()

  const [approved, setApproved] = useState(false)

  const onApprovalChange = useCallback((msg: any) => setApproved(msg === 'approved'), [])

  const tokenWithAmounts: ApproveButtonProps['tokenWithAmounts'] = useMemo(
    () => (token ? [[token]] : []),
    [token]
  )

  useEffect(() => {
    setApproved(false)
  }, [token])

  const buttons = [
    <SecondaryButton size="xl" onClick={reset}>
      Reset
    </SecondaryButton>
  ]

  if (!approved) {
    buttons.push(
      <ApproveButton
        spender={Config.multiSendAddress}
        onStateChange={onApprovalChange}
        tokenWithAmounts={tokenWithAmounts}
      />
    )
  } else if (status === 'idle') {
    if (errors.length === 0) {
      buttons.push(
        <PrimaryButton size="xl" onClick={send}>
          Send {totalAmount} {token && token.symbol}
        </PrimaryButton>
      )
    } else {
      buttons.push(
        <DangerButton size="xl" disabled={true}>
          Send {totalAmount} {token && token.symbol}
        </DangerButton>
      )
    }
  } else if (status === 'sent') {
    buttons.push(
      <PrimaryButton size="xl" onClick={send}>
        Sent!
      </PrimaryButton>
    )
  } else if (status === 'sending') {
    buttons.push(
      <PrimaryButton size="xl" disabled={true}>
        Sending {totalAmount} {token && token.symbol}
      </PrimaryButton>
    )
  }

  return (
    <SendButtonWrapper>
      {buttons.map((b, key) => (
        <span key={key}>{b}</span>
      ))}
    </SendButtonWrapper>
  )
}
