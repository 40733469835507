import React, { useCallback, useEffect, useMemo } from 'react'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  useModal,
  DangerButton
} from '@unifiprotocol/uikit'

import { useAdapter } from '../../Hooks/useAdapter'
import { YourAddress, Buttons } from './Styles'
import { shortAddress } from '../../Utils/Address'

type ConnectedModalProps = {
  onClose: () => void
}

const ConnectedModalComponent: React.FC<ConnectedModalProps> = ({ onClose }) => {
  const { adapter, disconnect, connectorName } = useAdapter()
  const address = adapter.getAddress()

  const onClickDisconnect = useCallback(() => {
    disconnect()
    onClose()
  }, [disconnect, onClose])

  return (
    <Modal>
      <ModalHeader>
        Connect wallet
        <ModalClose onClick={() => onClose()} />
      </ModalHeader>
      <ModalBody>
        <div>
          Connected to {adapter.blockchainConfig.blockchain} with {connectorName}
        </div>
        <YourAddress>
          Your address:
          <b>{shortAddress(address)}</b>
        </YourAddress>
        <Buttons>
          <DangerButton onClick={onClickDisconnect}>Disconnect</DangerButton>
        </Buttons>
      </ModalBody>
    </Modal>
  )
}

export const ConnectedModal: React.FC<ConnectedModalProps & { isOpen: boolean }> = ({
  onClose,
  isOpen
}) => {
  const props = useMemo(() => ({ onClose }), [onClose])
  const [open, close] = useModal({
    component: ConnectedModalComponent,
    props,
    options: { disableBackdropClick: true, onClose }
  })

  useEffect(() => {
    if (isOpen) {
      open()
    } else {
      close()
    }
  }, [isOpen, close, open])

  return <></>
}
