import { Card, CardBody, SecondaryButton, CgAdd } from '@unifiprotocol/uikit'

import React, { useCallback } from 'react'
import styled from 'styled-components'

import { SendLine } from './SendLine'
import { useMultiSend, emptyReceiver } from '../../Hooks/useMultiSend'
import { TokenSelector } from './TokenSelector'
import { ImportFromText } from './ImportFromText'
import { Messages } from './Messages'
import { SendButton } from './SendButton'

const Actions = styled.div`
  text-align: right;
`
const Receivers = styled.div`
  border: 3px solid ${(p) => p.theme.bgAlt2};
  border-radius: ${(p) => p.theme.borderRadius};
  padding: 1rem;
  margin: 1rem 0;
  table {
    margin-top: 0.5rem;
    width: 100%;
    th {
      text-align: left;
      padding-left: 0.5rem;
    }
    th:last-child {
      text-align: right;
    }
  }
  > div {
    justify-content: end;
    display: flex;
    gap: 0.5rem;
  }
`
const SendFormCard = styled(Card)`
  width: 100%;
`
interface SendFormProps {}

export const SendForm: React.FC<SendFormProps> = () => {
  const { setReceiver, receivers, addReceiver, canAddNewLine } = useMultiSend()

  const addLine = useCallback(() => {
    if (canAddNewLine) {
      addReceiver(emptyReceiver)
    }
  }, [addReceiver, canAddNewLine])

  return (
    <SendFormCard>
      <CardBody>
        <TokenSelector />
        <Receivers>
          <div>
            <ImportFromText />
            <SecondaryButton variant="outline" size="sm" onClick={addLine}>
              <CgAdd size={22} /> Add
            </SecondaryButton>
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Recipient</th>
                <th>Address</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {receivers.map((receiver, idx) => (
                <SendLine
                  receiver={receiver}
                  key={idx}
                  idx={idx}
                  onChange={(receiver) => setReceiver(idx, receiver)}
                />
              ))}
            </tbody>
          </table>
        </Receivers>
        <Messages />
        <Actions>
          <SendButton />
        </Actions>
      </CardBody>
    </SendFormCard>
  )
}
