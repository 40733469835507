import { atom } from 'recoil'

export type UpdaterState = {
  updatingBalances: boolean
  updatingPools: boolean
  updatingAutoPools: boolean
}

export const UpdaterStore = atom<UpdaterState>({
  key: 'UpdaterStore_v1_0_0',
  default: {
    updatingBalances: false,
    updatingPools: false,
    updatingAutoPools: false
  }
})
