import { BrandedHeader } from '@unifiprotocol/uikit'
import styled from 'styled-components'
import { NetworkSwitch } from '../NetworkSwitch'
import { Wallet } from '../Wallet'

const TopbarContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
`
export const Topbar = () => {
  return (
    <BrandedHeader>
      <TopbarContent>
        <NetworkSwitch />
        <Wallet />
      </TopbarContent>
    </BrandedHeader>
  )
}
