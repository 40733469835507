import React from 'react'
import styled from 'styled-components'
import { Topbar } from '../Topbar'

const Body = styled.div`
  margin-top: 5.5rem;
  display: flex;
  justify-content: center;
  position: relative;

  ${(props) => props.theme.mediaQueries.xs} {
    margin-top: 9rem;
  }
`

const Aligned = styled.div`
  padding: 0 2rem;
  width: 100%;
`

export const BodyWrapper: React.FC = ({ children }) => {
  return (
    <>
      <Topbar />
      <Body>
        <Aligned>{children}</Aligned>
      </Body>
    </>
  )
}
