import styled from 'styled-components'

export const NetworkLogo = styled.img`
  width: 1.2rem;
`
export const NetworkName = styled.span`
  margin-left: 0.3rem;
  display: none;
  ${(p) => p.theme.mediaQueries.sm} {
    display: inline;
  }
`

export const CurrentNetworkWrapper = styled.div`
  background: ${(p) => p.theme.bg};
  border-radius: ${(p) => p.theme.borderRadius};
  transition: 0.25s all;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.4rem;

  &.selected {
    cursor: default;
    border-color: ${(p) => p.theme.primary};
    background: ${(p) => p.theme.darkGrey};
  }

  &:hover {
    background: ${(p) => p.theme.bgAlt2};
  }
`
