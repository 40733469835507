import { atom, DefaultValue, selector } from 'recoil'
import {
  IAdapter,
  Connectors,
  IConnector,
  IMulticallAdapter,
  Blockchains
} from '@unifiprotocol/core-sdk'
import { Opt } from '@unifiprotocol/utils'
import { Config } from '../Config'
import { recoilPersist } from '../Utils/RecoilPersist'

export type AdapterState = {
  adapter: Opt<IAdapter>
  multicallAdapter: Opt<IMulticallAdapter>
  connector: Opt<IConnector>
}

export const Adapter = atom<AdapterState>({
  key: 'AdapterStore_v1_0_1',
  dangerouslyAllowMutability: true,
  default: {
    adapter: undefined,
    multicallAdapter: undefined,
    connector: undefined
  }
})

export type TAdapterConfig = {
  connectorName: Connectors | undefined
}

export type AdapterConfigState = {
  [K in Blockchains]?: TAdapterConfig
}

const { persistAtom: persistAdapterConfig } = recoilPersist<AdapterConfigState>()

const AdapterConfigStore = atom<AdapterConfigState>({
  key: 'AdapterConfigStore',
  dangerouslyAllowMutability: true,
  default: {},
  effects_UNSTABLE: [persistAdapterConfig]
})

export const AdapterConfig = selector({
  key: 'AdapterConfigSelector',
  dangerouslyAllowMutability: true,
  get: ({ get }) => {
    const state = get(AdapterConfigStore)
    const defaultConfig = {
      connectorName: Config.defaultOfflineConnector
    }
    return state[Config.blockchain] || defaultConfig
  },
  set: ({ set, get }, newValue: TAdapterConfig | DefaultValue) => {
    const currentState = get(AdapterConfigStore)
    set(
      AdapterConfigStore,
      newValue instanceof DefaultValue
        ? newValue
        : { ...currentState, [Config.blockchain]: newValue }
    )
  }
})
