import { Notification, NotificationAppearance } from '@unifiprotocol/uikit'

import { TrackedTransaction } from './Types'

export class TransactionNotification implements Notification {
  constructor(
    public readonly appearance: NotificationAppearance,
    public readonly transaction: TrackedTransaction,
    public readonly transactionLink: string,
    public readonly status: string
  ) {}
  get content() {
    return (
      <>
        {this.transaction.getText(this.status)}. <br />
        <a target="_blank" rel="noreferrer" href={this.transactionLink}>
          View transaction
        </a>
      </>
    )
  }
}

export class ConfirmedTransactionNotification extends TransactionNotification {
  constructor(transaction: TrackedTransaction, transactionLink: string) {
    super('success', transaction, transactionLink, 'confirmed')
  }
}

export class FailedTransactionNotification extends TransactionNotification {
  constructor(transaction: TrackedTransaction, transactionLink: string) {
    super('error', transaction, transactionLink, 'failed')
  }
}

export class TransactionSentNotification extends TransactionNotification {
  constructor(transaction: TrackedTransaction, transactionLink: string) {
    super('info', transaction, transactionLink, 'sent')
  }
}
