import { useCallback, useMemo, useState } from 'react'
import { atom, useRecoilState, useResetRecoilState } from 'recoil'
import { Receiver } from '../Types'
import { recoilPersist } from 'recoil-persist'
import { BN, Currency } from '@unifiprotocol/utils'
import { useBalances } from './useBalances'
import { isEthAddress } from '../Utils/ETH'
import { SendTokens } from '../Adapter/Contracts/MultiSend/SendTokens'
import { SendNative } from '../Adapter/Contracts/MultiSend/SendNative'
import { useAdapter } from './useAdapter'
import { TransactionType, useTrackedTransactions } from '../Transactions'
import { useCurrencies } from './useCurrencies'
import { Config } from '../Config'
import { ethers } from 'ethers'
import { MultiSendAbi } from '../Adapter/Contracts/MultiSend/Abi'
import { nonSuccessResponse, successResponse } from '@unifiprotocol/core-sdk'

export const emptyReceiver: Receiver = { alias: '', amount: '0', address: '' }

type TransferType = 'native' | 'token'
interface TMultiSendState {
  receivers: Receiver[]
  transferType: TransferType
  tokenAddress: string
}
const { persistAtom } = recoilPersist()

const MultiSendState = atom<TMultiSendState>({
  key: 'MultiSendState',
  default: {
    receivers: [emptyReceiver],
    transferType: 'native',
    tokenAddress: Config.nativeToken.address
  },
  effects_UNSTABLE: [persistAtom]
})

type MultiSendStatus = 'idle' | 'sending' | 'sent'

export const useMultiSend = () => {
  const [{ receivers, tokenAddress, transferType }, setState] = useRecoilState(MultiSendState)
  const resetState = useResetRecoilState(MultiSendState)
  const [status, setStatus] = useState<MultiSendStatus>('idle')
  const { adapter } = useAdapter()
  const { trackTransaction } = useTrackedTransactions()
  const { getBalanceByAddress } = useBalances()
  const { findCurrency } = useCurrencies()

  const token = useMemo(() => findCurrency(tokenAddress)!, [findCurrency, tokenAddress])

  const balanceOfAsset = useMemo(
    () =>
      getBalanceByAddress(
        transferType === 'native' ? Config.nativeToken.address : tokenAddress,
        true
      ),
    [getBalanceByAddress, tokenAddress, transferType]
  )

  const reset = useCallback(() => {
    resetState()
    setStatus('idle')
  }, [resetState])

  const setReceivers = useCallback(
    (receivers: Receiver[]) => setState((s) => ({ ...s, receivers })),
    [setState]
  )

  const setTransferType = useCallback(
    (transferType: TransferType) => setState((s) => ({ ...s, transferType })),
    [setState]
  )

  const setToken = useCallback(
    (token: Currency | string) =>
      setState((s) => ({
        ...s,
        tokenAddress: typeof token === 'string' ? token : token.address
      })),
    [setState]
  )

  const setReceiver = useCallback(
    (changedIdx: number, changedReceiver: Receiver) =>
      setState((state) => ({
        ...state,
        receivers: state.receivers.map((receiver, idx) =>
          idx === changedIdx ? changedReceiver : receiver
        )
      })),
    [setState]
  )

  const addReceiver = useCallback(
    (receiver: Receiver) => {
      setState((state) => ({ ...state, receivers: [...state.receivers, receiver] }))
    },
    [setState]
  )

  const removeReceiver = useCallback(
    (removeIdx: number) => {
      setState((state) => ({
        ...state,
        receivers: state.receivers.filter((r, index) => index !== removeIdx)
      }))
    },
    [setState]
  )

  const lastReceiver = receivers[receivers.length - 1]

  const canAddNewLine =
    !lastReceiver || (lastReceiver.address.trim() !== '' && lastReceiver.amount.trim() !== '')

  const totalAmount = useMemo(
    () =>
      receivers.reduce((total, receiver) => total.plus(receiver.amount || '0'), BN(0)).toFixed(),
    [receivers]
  )

  const send = useCallback(async () => {
    setStatus('sending')
    let sendUseCase: SendTokens | SendNative
    const amounts = receivers.map((r) => token.toPrecision(r.amount))
    const recipients = receivers.map((r) => r.address)

    if (transferType === 'native') {
      sendUseCase = new SendNative({
        amounts,
        recipients
      })
    } else {
      sendUseCase = new SendTokens({
        tokenAddress,
        amounts,
        recipients
      })
    }

    const contract = new ethers.Contract(
      Config.multiSendAddress,
      MultiSendAbi,
      adapter.getProvider().getSigner()
    )
    try {
      const res = await contract[sendUseCase.method](...sendUseCase.getArgs())

      if (res && res.hash) {
        trackTransaction({
          address: adapter!.getAddress(),
          params: { token: token.symbol },
          hash: res.hash,
          type: TransactionType.Send
        })
      }
      adapter
        .waitForTransaction(res.hash)
        .then((txStatus) => {
          setStatus(txStatus === 'SUCCESS' ? 'sent' : 'idle')
        })
        .catch((error) => {
          setStatus('idle')
        })
      return successResponse({
        hash: res.hash
      })
    } catch (error) {
      return nonSuccessResponse({
        err: error
      })
    }
    // const res = await sendUseCase
    //   .execute(adapter)
    //   .then((res) => {
    //     trackTransaction({
    //       address: adapter!.getAddress(),
    //       params: { token: token.symbol, total: totalAmount, receivers: `${receivers.length}` },
    //       hash: res.hash,
    //       type: TransactionType.Send
    //     })
    //     return res
    //   })
    //   .catch((error) => {
    //     setStatus('idle')
    //     throw error
    //   })
    // adapter
    //   .waitForTransaction(res.hash)
    //   .then((txStatus) => {
    //     setStatus(txStatus === 'SUCCESS' ? 'sent' : 'idle')
    //   })
    //   .catch((error) => {
    //     setStatus('idle')
    //   })
    // return res
  }, [adapter, receivers, token, tokenAddress, trackTransaction, transferType])

  const errors = useMemo(() => {
    const errors: string[] = []

    if (BN(totalAmount).isGreaterThan(balanceOfAsset)) {
      errors.push('Total amount exceeds your current balance')
    }

    const invalidRecipients = receivers.reduce(
      (invalids, r, idx) =>
        isEthAddress(r.address) ? invalids : [...invalids, r.alias || `#${idx + 1}`],
      [] as string[]
    )
    if (invalidRecipients.length > 0) {
      errors.push(`The following recipients are invalid: ${invalidRecipients.join(', ')}`)
    }

    return errors
  }, [receivers, totalAmount, balanceOfAsset])

  return {
    status,
    errors,
    balanceOfAsset,
    totalAmount,
    receivers,
    setReceivers,
    tokenAddress,
    token,
    setToken,
    transferType,
    setTransferType,
    setReceiver,
    addReceiver,
    removeReceiver,
    lastReceiver,
    canAddNewLine,
    send,
    reset
  }
}
