import { Blockchains } from '@unifiprotocol/core-sdk'
import { atom, DefaultValue, selector } from 'recoil'
import { recoilPersist } from '../Utils/RecoilPersist'
import { Config } from '../Config'
import { Currency } from '@unifiprotocol/utils'
import { currencyFromJson } from '../Utils/Currency'

export type CurrenciesState = {
  [K in Blockchains]?: Currency[]
}

const { persistAtom } = recoilPersist<CurrenciesState>({
  parser: (state) => {
    if (!state) return state
    return Object.keys(state).reduce((t: CurrenciesState, key) => {
      const blockchain = key as Blockchains
      const rawBlockchainState = state[blockchain]
      if (!rawBlockchainState) return t
      const blockchainCurrencies = rawBlockchainState.map(currencyFromJson)
      t[blockchain] = blockchainCurrencies
      return t
    }, {})
  }
})

const CurrenciesStore = atom<CurrenciesState>({
  key: 'CurrenciesStore',
  default: {},
  effects_UNSTABLE: [persistAtom]
})

export const Currencies = selector({
  key: 'CurrenciesSelector',
  get: ({ get }) => {
    const state = get(CurrenciesStore)
    return state[Config.blockchain] || []
  },
  set: ({ get, set }, newValue: Currency[] | DefaultValue) => {
    const currentState = get(CurrenciesStore)
    set(
      CurrenciesStore,
      newValue instanceof DefaultValue
        ? newValue
        : { ...currentState, [Config.blockchain]: newValue }
    )
  }
})
