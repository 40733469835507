import React, { useEffect, useMemo } from 'react'
import { Network } from '../../Entities/Network'
import { useNetwork } from '../../Hooks/useNetwork'
import { ItemLogo, ItemName, SelectionList, SelectionListItem } from '../SelectionModal/Styles'
import { Modal, ModalHeader, ModalBody, ModalClose, useModal } from '@unifiprotocol/uikit'
import { calcClassName } from '../../Utils/DOM'

type ChooseBlockchainModalProps = {
  currentNetwork: Network
  onNetworkChange: (network: Network) => void
  onlyV2?: boolean
  onClose: () => void
}

const ChooseNetworkComponent: React.FC<ChooseBlockchainModalProps> = ({
  onClose,
  onNetworkChange,
  currentNetwork,
  onlyV2
}) => {
  const { networks } = useNetwork()

  const options = useMemo(
    () =>
      networks
        .filter((n) => !n.beta)
        .filter((n) => {
          if (onlyV2) {
            return n.tags.includes('V2')
          }
          return true
        })
        .map((network) => ({
          value: network,
          display: (
            <>
              <ItemLogo alt={network.name} src={network.logoURI} />
              <ItemName>{network.name}</ItemName>
            </>
          )
        })),
    [networks, onlyV2]
  )

  return (
    <Modal>
      <ModalHeader>
        Choose network
        <ModalClose onClick={() => onClose()} />
      </ModalHeader>
      <ModalBody>
        <SelectionList>
          {options.map(({ value, display }, idx) => {
            const selected =
              currentNetwork.name === value.name && value.tags.includes('V2') && !onlyV2
            return (
              <SelectionListItem
                key={idx}
                onClick={() => (selected ? onClose() : onNetworkChange(value))}
                className={calcClassName({ selected })}
              >
                {display}
              </SelectionListItem>
            )
          })}
        </SelectionList>
      </ModalBody>
    </Modal>
  )
}

export const ChooseNetworkModal: React.FC<ChooseBlockchainModalProps & { isOpen: boolean }> = ({
  onClose,
  isOpen,
  currentNetwork,
  onNetworkChange,
  onlyV2
}) => {
  const props = useMemo(
    () => ({ currentNetwork, onNetworkChange, onlyV2, onClose }),
    [currentNetwork, onNetworkChange, onlyV2, onClose]
  )
  const [open, close] = useModal({
    component: ChooseNetworkComponent,
    props,
    options: { disableBackdropClick: true, onClose }
  })

  useEffect(() => {
    if (isOpen) {
      open()
    } else {
      close()
    }
  }, [isOpen, close, open])

  return <></>
}
