import { useCallback } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import {
  Connectors,
  getBlockchainConnectorByName,
  InvalidNetworkError,
  OfflineConnectors
} from '@unifiprotocol/core-sdk'
import { Adapter, AdapterConfig } from '../State/Adapter'
import { Config } from '../Config'
import Fetcher from '../Services/Fetcher'
import { AppEventBus } from '../EventBus'
import { AdapterConnected, AddressChanged } from '../EventBus/Events'
import { Balances } from '../State/Balances'
import { MultiSendAbi } from '../Adapter/Contracts/MultiSend/Abi'

export const useAdapter = () => {
  const resetBalances = useResetRecoilState(Balances)
  const [{ adapter, multicallAdapter, connector }, setAdapter] = useRecoilState(Adapter)

  const [{ connectorName }, setAdapterConfig] = useRecoilState(AdapterConfig)

  const connect = useCallback(
    async (newConnectorName: Connectors) => {
      try {
        if (connector) {
          connector.disconnect()
        }
        const newConnector = getBlockchainConnectorByName(Config.blockchain, newConnectorName)
        const { adapter: newAdapter, multicall } = await newConnector.connect()

        await newAdapter.initializeContract(Config.multiSendAddress, MultiSendAbi)

        Fetcher.setAdapter(newAdapter)
        Fetcher.setMulticallAdapter(multicall)

        setAdapter({ connector: newConnector, multicallAdapter: multicall, adapter: newAdapter })
        setAdapterConfig({ connectorName: newConnectorName })

        AppEventBus.emit(new AdapterConnected())
        newConnector.on('AddressChanged', async (address: string) => {
          AppEventBus.emit(new AddressChanged(address))
        })
        newConnector.on('NetworkChanged', (newChainId) => {
          if (newChainId !== Config.chainId) {
            newConnector.disconnect()
            resetBalances()
            connect(Config.defaultOfflineConnector)
          }
        })
      } catch (error) {
        if (error instanceof InvalidNetworkError) {
          connect(Config.defaultOfflineConnector)
        }
        throw error
      }
    },
    [connector, resetBalances, setAdapter, setAdapterConfig]
  )
  const disconnect = useCallback(() => {
    connector?.disconnect()
    connect(OfflineConnectors.Cloudflare)
  }, [connect, connector])

  return {
    connect,
    disconnect,
    get isConnected() {
      return !!adapter && !!adapter.getAddress()
    },
    get adapter() {
      return adapter!
    },
    get multicallAdapter() {
      return multicallAdapter!
    },

    connectorName
  }
}
