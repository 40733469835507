import { BaseEvent } from '../EventBus/BaseEvent'
import { TrackedTransaction } from './Types'

export const TransactionSentEvent = Symbol('TransactionSentEvent')
export const TransactionFailedEvent = Symbol('TransactionFailedEvent')
export const TransactionConfirmedEvent = Symbol('TransactionConfirmedEvent')

export abstract class TransactionEvent extends BaseEvent<TrackedTransaction> {}

export class TransactionSent extends TransactionEvent {
  constructor(payload: TrackedTransaction) {
    super(TransactionSentEvent, payload)
  }
}

export class TransactionFailed extends TransactionEvent {
  constructor(payload: TrackedTransaction) {
    super(TransactionFailedEvent, payload)
  }
}

export class TransactionConfirmed extends TransactionEvent {
  constructor(payload: TrackedTransaction) {
    super(TransactionConfirmedEvent, payload)
  }
}
