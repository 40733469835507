import { BaseEvent } from '../BaseEvent'

export const TokenListUpdatedEvent = Symbol('TokenListUpdatedEvent')
export const TokenSearchRequestEvent = Symbol('TokenSearchRequestEvent')

export class TokenListUpdated extends BaseEvent<void> {
  constructor() {
    super(TokenListUpdatedEvent)
  }
}

interface TokenSearchRequestEventPayload {
  address: string
}
export class TokenSearchRequest extends BaseEvent<TokenSearchRequestEventPayload> {
  constructor(payload: TokenSearchRequestEventPayload) {
    super(TokenSearchRequestEvent, payload)
  }
}
