import {
  CgClose,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  PrimaryButton,
  SecondaryButton,
  useModal
} from '@unifiprotocol/uikit'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMultiSend } from '../../Hooks/useMultiSend'

const TheTextarea = styled.textarea`
  padding: 1rem;
  width: 100%;
  max-height: 200px;
  margin-bottom: 1rem;
  background: ${(p) => p.theme.bgInput};
  border: none;
  border-radius: ${(p) => p.theme.borderRadius};
  color: ${(p) => p.theme.txt100};
`
const ModalText = ({ close }: ModalProps) => {
  const { transferType, tokenAddress, receivers, setTransferType, setToken, setReceivers } =
    useMultiSend()
  const [text, setText] = useState('')
  const updateReceiversFromText = useCallback(() => {
    const lines = text.split('\n')
    const first = lines.shift() as string
    const [transferType, tokenAddress] = first.split(':').map((p) => p.trim())
    const newReceivers = lines
      .filter((l) => l.trim() !== '')
      .map((l) => {
        const [alias, address, amount] = l.split(',').map((p) => p.trim())
        return { alias, address, amount }
      })
    setTransferType(transferType as any)
    setToken(tokenAddress)
    setReceivers(newReceivers)
  }, [text, setTransferType, setToken, setReceivers])

  useEffect(() => {
    setText(
      [
        `${transferType}:${tokenAddress}`,
        ...receivers.map((receiver) => `${receiver.alias},${receiver.address},${receiver.amount}`)
      ].join('\n')
    )
  }, [transferType, tokenAddress, receivers])

  return (
    <Modal>
      <ModalHeader>
        Import from text
        <CgClose onClick={close} />
      </ModalHeader>
      <ModalBody>
        <TheTextarea
          value={text}
          style={{ height: `${receivers.length * 30}px`, lineHeight: '20px' }}
          onChange={(evt) => setText(evt.target.value)}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PrimaryButton onClick={updateReceiversFromText}>Import</PrimaryButton>
        </div>
      </ModalBody>
    </Modal>
  )
}
const props = {}
export const ImportFromText = () => {
  const [open] = useModal({
    component: ModalText,
    props
  })
  return (
    <>
      <SecondaryButton variant="outline" onClick={open}>
        Import from text
      </SecondaryButton>
    </>
  )
}
