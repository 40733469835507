import { Blockchains } from '@unifiprotocol/core-sdk'

export class Network {
  constructor(
    public name: string,
    public short: Blockchains | string,
    public logoURI: string,
    public tags: string[] = [],
    public beta?: boolean
  ) {}
}
