import {
  TransactionConfirmed,
  TransactionConfirmedEvent,
  TransactionEvent,
  TransactionFailed,
  TransactionFailedEvent,
  TransactionSentEvent
} from '../TransactionEvents'

import { useCallback, useEffect, useState } from 'react'
import { useTrackedTransactions } from '../Hook'
import {
  ConfirmedTransactionNotification,
  FailedTransactionNotification,
  TransactionSentNotification
} from '../Notifications'
import { TrackedTransaction } from '../Types'
import { AppEventBus } from '../../EventBus'
import { useAdapter } from '../../Hooks/useAdapter'
import { useNotifications } from '@unifiprotocol/uikit'

type EventAndHandlerList = [symbol, (event: TransactionEvent) => any][]

export const TransactionTracking: React.FC<{}> = () => {
  const { adapter } = useAdapter()
  const { notify } = useNotifications()
  const [waitingTxs, setWaitingTxs] = useState<TrackedTransaction[]>([])
  const { untrackTransaction, updateTransaction, trackedTransactions } = useTrackedTransactions()

  const waitForTx = useCallback(
    (transaction: TrackedTransaction) => {
      if (!adapter) return

      if (waitingTxs.find((tx) => transaction.hash === tx.hash)) {
        return
      }

      setWaitingTxs((s) => [...s, transaction])

      if (!transaction.notified) {
        notify(new TransactionSentNotification(transaction, adapter.getTxLink(transaction.hash)))
      }
      updateTransaction(transaction, { notified: true })

      adapter.waitForTransaction(transaction.hash).then((res) => {
        const event =
          res === 'SUCCESS'
            ? new TransactionConfirmed(transaction)
            : new TransactionFailed(transaction)
        AppEventBus.emit(event)
        untrackTransaction(transaction)
        setWaitingTxs((s) => s.filter((waitingTx) => waitingTx.hash !== transaction.hash))
      })
    },
    [adapter, untrackTransaction, notify, updateTransaction, waitingTxs]
  )

  const txSent = useCallback((event: TransactionEvent) => waitForTx(event.payload), [waitForTx])

  const txConfirmed = useCallback(
    (event: TransactionEvent) =>
      notify(
        new ConfirmedTransactionNotification(event.payload, adapter.getTxLink(event.payload.hash))
      ),
    [notify, adapter]
  )
  const txFailed = useCallback(
    (event: TransactionEvent) =>
      notify(
        new FailedTransactionNotification(event.payload, adapter.getTxLink(event.payload.hash))
      ),
    [notify, adapter]
  )

  const waitForStoredTransactions = useCallback(() => {
    trackedTransactions.forEach(waitForTx)
  }, [trackedTransactions, waitForTx])

  const eventInitializers = useCallback(() => {
    if (!adapter) {
      return
    }

    const eventAndHandlers: EventAndHandlerList = [
      [TransactionFailedEvent, txFailed],
      [TransactionConfirmedEvent, txConfirmed],
      [TransactionSentEvent, txSent]
    ]

    eventAndHandlers.forEach(([eventName, handler]) => {
      AppEventBus.on(eventName, handler)
    })

    return function cleanup() {
      eventAndHandlers.forEach(([eventName, handler]) => {
        AppEventBus.off(eventName, handler)
      })
    }
  }, [adapter, txConfirmed, txFailed, txSent])

  useEffect(eventInitializers, [eventInitializers])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(waitForStoredTransactions, [])
  return <></>
}
