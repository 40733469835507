import { Blockchains } from '@unifiprotocol/core-sdk'
import { atom, DefaultValue, selector } from 'recoil'
import { recoilPersist } from '../Utils/RecoilPersist'
import { Config } from '../Config'
import { TrackedTransaction } from './Types'

export type TrackedTransactionsState = {
  [K in Blockchains]?: TrackedTransaction[]
}

const { persistAtom } = recoilPersist<TrackedTransactionsState>({
  parser: (state) => {
    if (!state) {
      return state
    }
    return Object.keys(state).reduce((t: TrackedTransactionsState, key) => {
      const blockchain = key as Blockchains
      const st = state[blockchain]
      if (!st) return t
      const blockchainTransactions = st.map((tx) => new TrackedTransaction({ ...tx }))
      t[blockchain] = blockchainTransactions
      return t
    }, {})
  }
})

const TrackedTransactionsStore = atom<TrackedTransactionsState>({
  key: 'TrackedTransactionsStore',
  default: {},
  dangerouslyAllowMutability: true,
  effects_UNSTABLE: [persistAtom]
})

export const TrackedTransactions = selector({
  key: 'TrackedTransactions',
  dangerouslyAllowMutability: true,
  get: ({ get }) => {
    const state = get(TrackedTransactionsStore)
    return state[Config.blockchain] || []
  },
  set: ({ set, get }, newValue: TrackedTransaction[] | DefaultValue) => {
    const currentState = get(TrackedTransactionsStore)
    set(
      TrackedTransactionsStore,
      newValue instanceof DefaultValue
        ? newValue
        : { ...currentState, [Config.blockchain]: newValue }
    )
  }
})
