export enum TransactionType {
  Approval = 'APPROVAL',
  Send = 'SEND'
}

export interface ITrackedTransaction {
  address: string
  type: TransactionType
  params: Record<string, string>
  hash: string
  notified: boolean
}
export class TrackedTransaction implements ITrackedTransaction {
  public address: string
  public type: TransactionType
  public params: Record<string, string>
  public hash: string
  public notified: boolean
  constructor({ address, type, params, hash, notified }: ITrackedTransaction) {
    this.address = address
    this.type = type
    this.params = params
    this.hash = hash
    this.notified = notified
  }

  getText(status: string) {
    return `${this.type.toLowerCase()} ${status}`
  }
}
