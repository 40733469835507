import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { UpdaterStore } from '../State/Updater'

export const useUpdater = () => {
  const [{ updatingBalances, updatingPools, updatingAutoPools }, setState] =
    useRecoilState(UpdaterStore)

  const setUpdatingBalances = useCallback(
    (updatingBalances: boolean) => {
      return setState((s) => {
        return {
          ...s,
          updatingBalances
        }
      })
    },
    [setState]
  )

  const setUpdatingPools = useCallback(
    (updatingPools: boolean) => {
      setState((s) => ({
        ...s,
        updatingPools
      }))
    },
    [setState]
  )

  const setUpdatingAutoPools = useCallback(
    (updatingAutoPools: boolean) => {
      setState((s) => ({
        ...s,
        updatingAutoPools
      }))
    },
    [setState]
  )

  return {
    updatingBalances,
    updatingTokens: updatingPools,
    updatingPools,
    updatingAutoPools,
    setUpdatingBalances,
    setUpdatingPools,
    setUpdatingAutoPools
  }
}
