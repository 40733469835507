
type Deserializers = [string, (value: any) => any][]

type PersistedWrapper = { value: any; expiresAt: number }
export class PersistableCacheService {

  constructor(
    private storagePrefix: string,
    private options: { stdTTL: number },
    private deserializers: Deserializers = []
  ) {
    
  }

  private getKeyMatcher(key: string) {
    return new RegExp(`^${this.getInternalCacheKey(key)}`)
  }

  protected getInternalCacheKey(args: string | string[]) {
    if (Array.isArray(args)) {
      return [this.storagePrefix, ...args].join('-')
    }
    return [this.storagePrefix, args].join('-')
  }

  set<T>(key: string | string[], value: T, ttl: number = this.options.stdTTL) {
    const internalKey = this.getInternalCacheKey(key)
    const persistedValue: PersistedWrapper = {
      value,
      expiresAt: ttl === 0 ? 0 : Date.now() + ttl * 1000
    }
     localStorage.setItem(internalKey, JSON.stringify(persistedValue))

  }

  get<T>(key: string | string[]): T | undefined {
    const internalKey = this.getInternalCacheKey(key)


    if (localStorage.getItem(internalKey)) {
      return this.restoreFromStorage(internalKey)
    }

    return undefined
  }

  protected findDeserializerForKey(key: string) {
    return this.deserializers.find(([deserializerKey]) => {
      return key.match(this.getKeyMatcher(deserializerKey))
    })
  }
  private isExpired(expiresAt: PersistedWrapper['expiresAt']) {
    return expiresAt > 0 && Date.now() > expiresAt
  }
  private getTimeToExpire(expiresAt: number) {
    return expiresAt === 0 ? 0 : Math.floor(expiresAt - Date.now())
  }
  private restoreFromStorage(key: string) {
    const deserializer = this.findDeserializerForKey(key)
    const rawValue = localStorage.getItem(key)
    if (!rawValue) {
      return rawValue
    }

    try {
      const { value, expiresAt }: PersistedWrapper = JSON.parse(rawValue)
      if (this.isExpired(expiresAt)) {
        localStorage.removeItem(key)
        return undefined
      }

      if (!deserializer) {
        return value
      }

      const restoredValue = deserializer[1](value)
      return restoredValue
    } catch (error) {
      // we do not want wrong values in storage
      localStorage.removeItem(key)
    }
  }
}
