import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { Currencies } from '../State/Currency'
import { uniqueCurrencyList } from '../Utils/Currency'
import Fetcher from '../Services/Fetcher'
import { Config } from '../Config'
import { Currency } from '@unifiprotocol/utils'

export const useCurrencies = () => {
  const [currencies, setTokenList] = useRecoilState(Currencies)

  const findCurrency = useCallback(
    (address: string) => {
      return currencies.find((t) => t.address.toLowerCase() === address.toLowerCase())
    },
    [currencies]
  )

  const addCurrency = useCallback(
    (newCurrency: Currency) => {
      setTokenList((st) => [...uniqueCurrencyList([...st, newCurrency])])
    },
    [setTokenList]
  )

  const findOrAddCurrency = useCallback(
    async (tokenAddress: string) => {
      let token = currencies.find(
        (token) => token.address.toLowerCase() === tokenAddress.toLowerCase()
      )

      if (token) {
        return token
      }

      token = await Fetcher.fetchTokenData(tokenAddress).catch(() => undefined)

      if (!token) {
        return undefined
      }

      addCurrency(token)
      return token
    },
    [addCurrency, currencies]
  )

  const setCurrencies = useCallback(
    (newCurrencies: Currency[]) => {
      setTokenList((s) =>
        uniqueCurrencyList(s, newCurrencies, [Config.nativeToken, Config.wrappedToken])
      )
    },
    [setTokenList]
  )

  return {
    findOrAddCurrency,
    findCurrency,
    addCurrency,
    setCurrencies,
    tokenList: currencies,
    currencies
  }
}
