import { Blockchains, OfflineConnectors } from '@unifiprotocol/core-sdk'
import { Currency } from '@unifiprotocol/utils'
import { IConfig } from './IConfig'

export const BinanceConfig: IConfig = {
  blockchain: Blockchains.Binance,
  defaultOfflineConnector: OfflineConnectors.BscDataseed,
  chainId: 56,
  nativeToken: new Currency('BNB', 18, 'BNB', 'BNB'),
  wrappedToken: new Currency(
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB'
  ),
  multiSendAddress: '0x5e3aB19dB325089DDE482E13da01BA16531715E4',
  defaultTokenList: [
    '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    '0x728c5bac3c3e370e372fc4671f9ef6916b814d8b'
  ]
}
