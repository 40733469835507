import { useCallback, useEffect, useMemo, useState } from 'react'
import { DangerButton, PrimaryButton } from '@unifiprotocol/uikit'

import { CgSpinner } from '@unifiprotocol/uikit'
import { usePrevious } from '../../Hooks/usePrevious'
import { Currency } from '@unifiprotocol/utils'
import { useAdapter } from '../../Hooks/useAdapter'
import { useContracts } from '../../Hooks/useContracts'

type ApprovalValue = string | undefined

export interface ApproveButtonProps {
  tokenWithAmounts: Array<[Currency, ApprovalValue] | [Currency]>
  spender: string
  onStateChange?: (state: ApproveButtonState) => void
}

export type ApproveButtonState =
  | 'idle'
  | 'checkingAllowance'
  | 'pendingApproval'
  | 'requestingApproval'
  | 'waitingApprovalTx'
  | 'approved'

export const ApproveButton = ({
  tokenWithAmounts,
  spender,
  onStateChange = undefined
}: ApproveButtonProps) => {
  const { hasAllowance, approve } = useContracts()
  const { adapter } = useAdapter()

  const [state, _setState] = useState<ApproveButtonState>('idle')
  const prevTokenWithAmounts = usePrevious<typeof tokenWithAmounts>(tokenWithAmounts)
  const [tokensWithoutAllowance, setTokensWithoutAllowance] = useState<number[]>([])

  const tokensWithoutAllowanceSymbols = tokensWithoutAllowance.map(
    (idx) => tokenWithAmounts[idx][0].symbol
  )
  const setState = useCallback(
    (newState: ApproveButtonState) => {
      _setState(newState)
      onStateChange && onStateChange(newState)
    },
    [_setState, onStateChange]
  )

  const checkAllowance = useCallback(() => {
    setState('checkingAllowance')
    Promise.all(
      tokenWithAmounts.map(([token, amount]) => {
        return hasAllowance(token, spender, amount)
      })
    )
      .then((allowances) => {
        const _tokensWithoutAllowance: number[] = []

        allowances.forEach((allowed, idx) => {
          if (!allowed) {
            _tokensWithoutAllowance.push(idx)
          }
        })
        setTokensWithoutAllowance(_tokensWithoutAllowance)
        setState(_tokensWithoutAllowance.length === 0 ? 'approved' : 'pendingApproval')
      })
      .catch(() => setState('pendingApproval'))
  }, [hasAllowance, tokenWithAmounts, spender, setState])

  const requestAllowance = useCallback(async () => {
    setState('requestingApproval')

    const approvalResponses = await Promise.all(
      tokensWithoutAllowance.map((idx) => {
        const [token] = tokenWithAmounts[idx]
        return approve(token, spender)
      })
    )

    const success = approvalResponses.every((r) => r.success)
    setState(success ? 'waitingApprovalTx' : 'pendingApproval')

    const approvalTxs = await Promise.all(
      approvalResponses.map(async ({ success, hash }) => {
        if (!success) {
          return 'FAILED'
        }
        if (!hash) {
          return 'SUCCESS'
        }
        return adapter!.waitForTransaction!(hash)
      })
    )
    const allApprovalTxsSucceded = approvalTxs.every((result) => result === 'SUCCESS')
    if (allApprovalTxsSucceded) {
      setState('approved')
    } else {
      checkAllowance()
    }
  }, [
    tokensWithoutAllowance,
    adapter,
    approve,
    setState,
    tokenWithAmounts,
    checkAllowance,
    spender
  ])

  const tokenWithAmountHasChanged = useMemo(() => {
    if (prevTokenWithAmounts === tokenWithAmounts) {
      return false
    }
    if (!prevTokenWithAmounts || prevTokenWithAmounts.length === 0) {
      return true
    }

    for (let i = 0; i < tokenWithAmounts.length; i++) {
      const sameCurrency = prevTokenWithAmounts[i][0].equals(tokenWithAmounts[i][0])
      const sameAmount = prevTokenWithAmounts[i][1] === tokenWithAmounts[i][1]
      if (!sameCurrency || !sameAmount) {
        return true
      }
    }
    return false
  }, [prevTokenWithAmounts, tokenWithAmounts])

  useEffect(() => {
    // const dontCheckStatuses: ApproveButtonState[] = [
    //   'checkingAllowance',
    //   'requestingApproval',
    //   'waitingApprovalTx',
    //   'approved'
    // ]
    if (tokenWithAmountHasChanged) {
      checkAllowance()
    }
    // do not want to use effect on state change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenWithAmountHasChanged, checkAllowance])

  if (!adapter.isConnected()) {
    return <DangerButton size="xl">Connect your wallet</DangerButton>
  }

  return (
    <>
      {state === 'checkingAllowance' && (
        <PrimaryButton size="xl" disabled={true}>
          <CgSpinner className="spin" size={20}></CgSpinner> Checking allowance
        </PrimaryButton>
      )}
      {state === 'pendingApproval' && (
        <PrimaryButton size="xl" onClick={() => requestAllowance()}>
          Approve {tokensWithoutAllowanceSymbols}
        </PrimaryButton>
      )}
      {state === 'requestingApproval' && (
        <PrimaryButton size="xl" style={{ cursor: 'processing' }} disabled={true}>
          Please, sign transaction
        </PrimaryButton>
      )}
      {state === 'waitingApprovalTx' && (
        <PrimaryButton size="xl" disabled={true}>
          <CgSpinner className="spin" size={20}></CgSpinner> Waiting transaction
        </PrimaryButton>
      )}
      {state === 'approved' && (
        <PrimaryButton size="xl" disabled={true}>
          {/* <CgCheck size={10}></CgCheck> */}
          Approved!
        </PrimaryButton>
      )}
    </>
  )
}
