import styled from 'styled-components'

export const YourAddress = styled.div`
  padding: 1rem;
  background: ${(p) => p.theme.bgAlt2};
  border-radius: ${(p) => p.theme.borderRadius};
  margin: 1rem 0;
  b {
    display: block;
    font-size: 120%;
    font-weight: bold;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
