import { useEffect } from 'react'

import Clock from '../../Utils/Clock'
import { BalancesUpdater } from './BalanceUpdater'
import { CurrencyUpdater } from './CurrencyUpdater'

export const Updater = () => {
  useEffect(() => {
    Clock.start()
  }, [])

  return (
    <>
      <BalancesUpdater />
      <CurrencyUpdater />
    </>
  )
}
