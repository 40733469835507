import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { ERC20ContractMethod } from './ContractMethod'

export interface DecimalsParams {
  tokenAddress: string
}

export class Decimals extends ContractUseCase<ERC20ContractMethod, DecimalsParams, string> {
  constructor(params: DecimalsParams) {
    super(params.tokenAddress, ERC20ContractMethod.Decimals, params, false)
  }
}
