import { CgRemove, DangerButton, Input } from '@unifiprotocol/uikit'
import { BN } from '@unifiprotocol/utils'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useMultiSend } from '../../Hooks/useMultiSend'
import { Receiver } from '../../Types'
import { calcClassName } from '../../Utils/DOM'
import { isEthAddress } from '../../Utils/ETH'

const SendLineWrapper = styled.tr`
  .error * {
    color: ${(p) => p.theme.danger}!important;
  }
`

interface SendLineProps {
  receiver: Receiver
  idx: number
  onChange: (receiver: Receiver) => void
}

export const SendLine: React.FC<SendLineProps> = ({ receiver, idx, onChange }) => {
  const { receivers, removeReceiver, balanceOfAsset } = useMultiSend()

  const onChangeAmount = useCallback(
    (amount: string) => {
      let amountParsed = amount.trim()
      if (amountParsed.length > 0 && BN(amountParsed).isNaN()) {
        amountParsed = '0'
      }
      onChange({ ...receiver, amount: amountParsed })
    },
    [onChange, receiver]
  )

  return (
    <SendLineWrapper>
      <td>#{idx + 1}</td>
      <td>
        <div>
          <Input
            value={receiver.alias}
            placeholder={`John Doe #${idx}`}
            onChange={(evt) => onChange({ ...receiver, alias: evt.target.value })}
          />
        </div>
      </td>
      <td style={{ width: '27rem' }}>
        <div className={calcClassName({ error: !isEthAddress(receiver.address) })}>
          <Input
            value={receiver.address}
            placeholder="0xA..."
            onChange={(evt) => onChange({ ...receiver, address: evt.target.value })}
          />
        </div>
      </td>
      <td>
        <div
          className={calcClassName({ error: BN(receiver.amount).isGreaterThan(balanceOfAsset) })}
        >
          <Input value={receiver.amount} onChange={(evt) => onChangeAmount(evt.target.value)} />
        </div>
      </td>
      <td>
        <div>
          {receivers.length > 1 && (
            <DangerButton
              onlyIcon={true}
              size="sm"
              variant="outline"
              onClick={() => removeReceiver(idx)}
            >
              <CgRemove size={22} />
            </DangerButton>
          )}
        </div>
      </td>
    </SendLineWrapper>
  )
}
