import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { ERC20ContractMethod } from './ContractMethod'

export interface NameParams {
  tokenAddress: string
}

export class Name extends ContractUseCase<ERC20ContractMethod, NameParams> {
  constructor(params: NameParams) {
    super(params.tokenAddress, ERC20ContractMethod.Name, params, false)
  }
}
