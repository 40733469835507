import { Blockchains } from '@unifiprotocol/core-sdk'
import { ChooseNetworkModal } from '../../Components/ChooseNetworkModal'
import { Network } from '../../Entities/Network'
import { useNetwork } from '../../Hooks/useNetwork'
import { useCallback, useState } from 'react'

import { setChainOnStorage } from '../../Utils/ChainStorage'
import { CurrentNetworkWrapper, NetworkName, NetworkLogo } from './Styles'

export const NetworkSwitch: React.FC<{}> = () => {
  const [selectingNetwork, selectNetwork] = useState(false)
  const { network } = useNetwork()

  const onNetworkChange = useCallback((network: Network) => {
    selectNetwork(false)
    const currentUrl = new URL(window.location.toString())

    setChainOnStorage(network.short as Blockchains)
    currentUrl.searchParams.delete('blockchain')
    currentUrl.searchParams.append('blockchain', network.short.toLowerCase())

    window.location = currentUrl.toString() as any
  }, [])

  return (
    <>
      <CurrentNetworkWrapper onClick={() => selectNetwork(true)}>
        <NetworkLogo src={network.logoURI} alt={network.name} />
        <NetworkName>{network.short}</NetworkName>
      </CurrentNetworkWrapper>
      <ChooseNetworkModal
        currentNetwork={network}
        isOpen={selectingNetwork}
        onNetworkChange={onNetworkChange}
        onClose={() => selectNetwork(false)}
      />
    </>
  )
}
