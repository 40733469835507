import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { ERC20ContractMethod } from './ContractMethod'

export interface HasAllowanceContractParams {
  owner: string
  spender: string
  tokenAddress: string
}

export class Allowance extends ContractUseCase<
  ERC20ContractMethod,
  HasAllowanceContractParams,
  string
> {
  constructor(params: HasAllowanceContractParams) {
    super(params.tokenAddress, ERC20ContractMethod.Allowance, params, false)
  }

  getArgs() {
    return [this.params.owner, this.params.spender]
  }
}
