import { Blockchains, OfflineConnectors } from '@unifiprotocol/core-sdk'
import { Currency } from '@unifiprotocol/utils'
import { EthereumConfig } from './EthereumConfig'
import { IConfig } from './IConfig'

export const RopstenConfig: IConfig = {
  ...EthereumConfig,
  blockchain: Blockchains.EthereumRopsten,
  defaultOfflineConnector: OfflineConnectors.EtherScan,
  chainId: 3,
  nativeToken: new Currency('ETH', 18, 'ETH', 'ETH'),
  wrappedToken: new Currency('0x81aD5fbAa4ec85Ca6F9C97861522D8491fB4F77F', 18, 'WETH', 'WETH'),
  multiSendAddress: '0x7787f2dA6c8Dc87936b160717a521a8636416867',
  defaultTokenList: []
}
