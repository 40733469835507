import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { TrackedTransactions } from './Store'
import { TrackedTransaction, ITrackedTransaction } from './Types'
import { TransactionSent } from './TransactionEvents'
import { AppEventBus } from '../EventBus'

export const useTrackedTransactions = () => {
  const [trackedTransactions, setTransactions] = useRecoilState(TrackedTransactions)

  const trackTransaction = useCallback(
    (partial: Omit<ITrackedTransaction, 'waiting' | 'notified'>) => {
      if (!partial.hash) {
        return
      }
      const transaction = new TrackedTransaction({
        ...partial,
        notified: false
      })
      setTransactions((s) => [...s, transaction])
      AppEventBus.emit(new TransactionSent(transaction))
    },
    [setTransactions]
  )

  const untrackTransaction = useCallback(
    (transaction: TrackedTransaction) => {
      setTransactions((s) => s.filter((storedTx) => storedTx.hash !== transaction.hash))
    },
    [setTransactions]
  )

  const updateTransaction = useCallback(
    (transaction: TrackedTransaction, changes: Partial<TrackedTransaction>) => {
      setTransactions((s) =>
        s.map((storedTx) => {
          if (transaction.hash === storedTx.hash) {
            return new TrackedTransaction({ ...storedTx, ...changes })
          }
          return storedTx
        })
      )
    },
    [setTransactions]
  )

  return {
    updateTransaction,
    trackedTransactions,
    untrackTransaction,
    trackTransaction
  }
}
