import { BaseEvent } from '../BaseEvent'

export const AdapterConnectedEvent = Symbol('AdapterConnectedEvent')
export const AddressChangedEvent = Symbol('AddressChangedEvent')

export class AdapterConnected extends BaseEvent<void> {
  constructor() {
    super(AdapterConnectedEvent)
  }
}

export class AddressChanged extends BaseEvent<string> {
  constructor(address: string) {
    super(AddressChangedEvent, address)
  }
}
