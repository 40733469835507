import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { ERC20ContractMethod } from './ContractMethod'

export interface SymbolParams {
  tokenAddress: string
}

export class Symbol extends ContractUseCase<ERC20ContractMethod, SymbolParams, string> {
  constructor(params: SymbolParams) {
    super(params.tokenAddress, ERC20ContractMethod.Symbol, params, false)
  }
}
