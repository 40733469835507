import { AtomEffect, DefaultValue } from 'recoil'

/**
 * Exact copy of https://github.com/polemius/recoil-persist
 * but adding the parser feature. One day I will do a MR to the original project.
 */

export interface PersistStorage {
  setItem(key: string, value: string): void | Promise<void>

  getItem(key: string): null | string | Promise<string>
}

export interface PersistConfiguration<StoreType> {
  key?: string
  storage?: PersistStorage
  parser?: (item: StoreType) => StoreType
}

/**
 * Recoil module to persist state to storage
 *
 * @param config Optional configuration object
 * @param config.key Used as key in local storage, defaults to `recoil-persist`
 * @param config.storage Local storage to use, defaults to `localStorage`
 */
export const recoilPersist = <StoreType = any>(
  config: PersistConfiguration<StoreType> = {}
): { persistAtom: AtomEffect<any> } => {
  if (typeof window === 'undefined') {
    return {
      persistAtom: () => {}
    }
  }

  const { key = 'recoil-persist-v2', storage = localStorage } = config

  const persistAtom: AtomEffect<any> = ({ onSet, node, trigger, setSelf }) => {
    const setSelfParsed = (value: StoreType) => {
      if (config.parser) {
        return setSelf(config.parser(value))
      }
      setSelf(value)
    }
    if (trigger === 'get') {
      const state = getState(node.key)
      if (typeof state.then === 'function') {
        state.then((s: any) => {
          setSelfParsed(s)
        })
      }

      setSelfParsed(state)
    }

    onSet(async (newValue) => {
      const state = await getState(node.key)
      if (
        newValue !== null &&
        newValue !== undefined &&
        newValue instanceof DefaultValue &&
        state.hasOwnProperty(node.key)
      ) {
        delete state[node.key]
      } else {
        state[node.key] = newValue
      }

      setState(state, node.key)
    })
  }

  const getState = (nodeKey: string): any => {
    const toParse = storage.getItem(`${key}.${nodeKey}`)
    if (toParse === null || toParse === undefined) {
      return {}
    }
    if (typeof toParse === 'string') {
      return parseState(toParse)
    }
    if (typeof toParse.then === 'function') {
      return toParse.then(parseState)
    }

    return {}
  }

  const parseState = (state: string) => {
    if (state === undefined) {
      return {}
    }
    try {
      return JSON.parse(state)
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  const setState = (state: any, nodeKey: string): void => {
    try {
      //TODO for React Native `AsyncStorage`, we should be using `mergeItem`
      // to merge existing stringified object with new one
      storage.setItem(`${key}.${nodeKey}`, JSON.stringify(state[nodeKey]))
    } catch (e) {
      console.error(e)
    }
  }

  return { persistAtom }
}
