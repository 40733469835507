import { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  errors: string[]
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errors: []
  }

  public static getDerivedStateFromError(err: Error): State {
    return { hasError: true, errors: [] }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState((st) => ({
      errors: [...st.errors, JSON.stringify(error.toString())]
    }))
    // HERE, BELOW, WE SHOULD ADD ALL THE THIRD PARTIES FOR ERROR HANDLING
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ color: '#fff' }}>
          <h1>Sorry.. there was an error</h1>
          {this.state.errors.map((err) => {
            return <div>{err}</div>
          })}
        </div>
      )
    }

    return this.props.children
  }
}
