import { DangerAlert } from '@unifiprotocol/uikit'

import { useMultiSend } from '../../Hooks/useMultiSend'

export const Messages = () => {
  const { errors } = useMultiSend()

  if (errors.length > 0) {
    return (
      <DangerAlert>
        <ul>
          {errors.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </DangerAlert>
    )
  }

  return <></>
}
