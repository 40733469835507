import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { BN } from '@unifiprotocol/utils'
import { Config } from '../../../Config'
import { MultiSendContractMethod } from './ContractMethod'

export interface SendNativeParams {
  recipients: string[]
  amounts: string[]
}

export class SendNative extends ContractUseCase<MultiSendContractMethod, SendNativeParams> {
  constructor(params: SendNativeParams) {
    super(Config.multiSendAddress, MultiSendContractMethod.SendNative, params, true)
  }
  getCallValue(): string | number | undefined {
    return this.params.amounts
      .reduce((total, amount) => {
        return total.plus(amount)
      }, BN(0))
      .toFixed()
  }
  getArgs() {
    return [this.params.recipients, this.params.amounts]
  }
}
