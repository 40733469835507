import { ShinyHeader } from '@unifiprotocol/uikit'
import styled from 'styled-components'
import { SendForm } from '../../Components/SendForm'

const HomeWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Home = () => {
  return (
    <HomeWrapper>
      <ShinyHeader>Multisender</ShinyHeader>

      <SendForm />
    </HomeWrapper>
  )
}
