import { Blockchains, OfflineConnectors } from '@unifiprotocol/core-sdk'
import { Currency } from '@unifiprotocol/utils'
import { IConfig } from './IConfig'

export const EthereumConfig: IConfig = {
  blockchain: Blockchains.Ethereum,
  defaultOfflineConnector: OfflineConnectors.Cloudflare,
  chainId: 1,
  nativeToken: new Currency('ETH', 18, 'ETH', 'ETH'),
  wrappedToken: new Currency('0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', 18, 'WETH', 'WETH'),
  multiSendAddress: '0x120D0Ec2E3E2e1be7236b3f928568a1EeB105693',
  defaultTokenList: [
    '0xdac17f958d2ee523a2206206994597c13d831ec7',
    '0x441761326490cacf7af299725b6292597ee822c2'
  ]
}
