import { useCallback, useEffect } from 'react'

import { AppEventBus } from '../../EventBus'
import { TokenSearchRequest, TokenSearchRequestEvent } from '../../EventBus/Events'
import { isEthAddress } from '../../Utils/ETH'
import { useCurrencies } from '../../Hooks/useCurrencies'
import { Config } from '../../Config'
import Fetcher from '../../Services/Fetcher'

export const CurrencyUpdater = () => {
  const { setCurrencies, findOrAddCurrency } = useCurrencies()

  const onTokenSearchRequest = useCallback(
    async (event: TokenSearchRequest) => {
      const { address } = event.payload
      if (isEthAddress(address)) {
        findOrAddCurrency(address)
      }
    },
    [findOrAddCurrency]
  )

  useEffect(() => {
    AppEventBus.on(TokenSearchRequestEvent, onTokenSearchRequest)
    return () => {
      AppEventBus.off(TokenSearchRequestEvent, onTokenSearchRequest)
    }
  }, [onTokenSearchRequest])

  useEffect(() => {
    Fetcher.fetchTokensData(Config.defaultTokenList).then(setCurrencies)
  }, [setCurrencies])

  return <></>
}
