import React, { useCallback, useEffect, useMemo } from 'react'
import { getBlockchainWalletConnectors, WalletConnectors } from '@unifiprotocol/core-sdk'

import { ItemName, SelectionList, SelectionListItem } from '../SelectionModal/Styles'
import { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalClose, useModal } from '@unifiprotocol/uikit'
import { useAdapter } from '../../Hooks/useAdapter'
import { Config } from '../../Config'

const availableWallets = getBlockchainWalletConnectors(Config.blockchain)

const options = availableWallets.map((wallet) => {
  return {
    value: wallet.name,
    display: (
      <>
        {/*<ItemLogo src={wallet.logoURILight} alt={wallet.displayName} />*/}
        <ItemName>{wallet.displayName}</ItemName>
      </>
    )
  }
})

type ConnectionModalProps = {
  onClose: () => void
  onConnectionError: (error: Error) => void
  onConnect: () => void
}

const ConnectionModalComponent: React.FC<ConnectionModalProps> = ({
  onClose,
  onConnectionError,
  onConnect
}) => {
  const { connect } = useAdapter()
  const [, setConnecting] = useState(false)

  const onWalletSelected = useCallback(
    (connectorName: WalletConnectors) => {
      setConnecting(true)
      connect(connectorName)
        .then(() => {
          onConnect()
          setConnecting(false)
        })
        .catch((error: any) => {
          onConnectionError(error)
          setConnecting(false)
        })
    },
    [connect, onConnect, onConnectionError]
  )

  return (
    <Modal>
      <ModalHeader>
        Connect wallet
        <ModalClose onClick={() => onClose()} />
      </ModalHeader>
      <ModalBody>
        Select a wallet
        <SelectionList>
          {options.map(({ value, display }, idx) => {
            return (
              <SelectionListItem key={idx} onClick={() => onWalletSelected(value as any)}>
                {display}
              </SelectionListItem>
            )
          })}
        </SelectionList>
      </ModalBody>
    </Modal>
  )
}

export const ConnectionModal: React.FC<ConnectionModalProps & { isOpen: boolean }> = ({
  onClose,
  onConnectionError,
  onConnect,
  isOpen
}) => {
  const props = useMemo(
    () => ({ onClose, onConnectionError, onConnect }),
    [onClose, onConnectionError, onConnect]
  )
  const [open, close] = useModal({
    component: ConnectionModalComponent,
    props,
    options: { disableBackdropClick: true, onClose }
  })

  useEffect(() => {
    if (isOpen) {
      open()
    } else {
      close()
    }
  }, [isOpen, close, open])

  return <></>
}
