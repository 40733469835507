import { Currency } from '@unifiprotocol/utils'
import { Config } from '../Config'

export function areSameCurrency(currencyA: Currency, currencyB: Currency) {
  return currencyA.equals(currencyB)
}

export function wrappedCurrency(currency: Currency) {
  return Config.nativeToken.equals(currency) ? Config.wrappedToken : currency
}

export function unwrappedCurrency(currency: Currency) {
  return Config.wrappedToken.equals(currency) ? Config.nativeToken : currency
}

export const currencyFromJson = (c: Currency) => {
  return new Currency(c.address, c.decimals, c.symbol, c.name)
}

export const uniqueCurrencyList = (...currencyLists: Currency[][]) => {
  let unique: { [addr: string]: Currency } = {}
  currencyLists.forEach((currencies) => {
    currencies.forEach((currency) => (unique[currency.address.toLowerCase()] = currency))
  })
  return Object.values(unique)
}

export function filterTokens(
  tokenList: { currency: Currency; balance: string }[],
  query: string
): { currency: Currency; balance: string }[] {
  if (query === '') {
    return tokenList
  }
  const re = new RegExp(query, 'i')
  const newTokenList = tokenList.filter(({ currency }) => {
    return currency.symbol.match(re) || currency.address.match(re) || currency.name.match(re)
  })
  return newTokenList
}
