import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { Config } from '../../../Config'
import { MultiSendContractMethod } from './ContractMethod'

export interface SendTokensParams {
  tokenAddress: string
  recipients: string[]
  amounts: string[]
}

export class SendTokens extends ContractUseCase<MultiSendContractMethod, SendTokensParams> {
  constructor(params: SendTokensParams) {
    super(Config.multiSendAddress, MultiSendContractMethod.SendTokens, params, true)
  }

  getArgs() {
    return [this.params.tokenAddress, this.params.recipients, this.params.amounts]
  }
}
